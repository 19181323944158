









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue, minLength, maxLength } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class EnterCode extends Vue {
  @Prop() private msg!: string;
  show = true;
  codeInvalid = false;

  showOverlay = false;
  form = {
    code: null
  }

  validations() {
    return {
      form: {
        code: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6)
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      code: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    console.log(this.form.code);
    this.codeInvalid = false;
    this.$store.dispatch('coupon/verifyCouponCode', this.form.code).then(response => {
      console.log(response);
    })
    .catch(err => {
      console.log(err);
      this.codeInvalid = true;
    });
  }

}
